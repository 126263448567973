<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title">Группы турниров</h2>
      <router-link to="/tournaments" class="ellipse-button">
        все турниры
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>
      </router-link>
    </div>
    <div class="col3-grid"
         :class="{even: this.GET_TOURNAMENT_GROUPS.length % 2 === 0, only5:this.GET_TOURNAMENT_GROUPS.length === 5, single: this.GET_TOURNAMENT_GROUPS.length === 1}">
      <tournament-group v-for="group in this.GET_TOURNAMENT_GROUPS" :key="group.id" :group="group"/>
    </div>
  </div>

</template>

<script>
import TournamentGroup from "@/components/tournamentGroups/tournamentGroup/tournamentGroup";
import {mapActions, mapGetters} from "vuex";
import Media from 'vue-media';

export default {
  name: "tournamentGroups",
  components: {TournamentGroup},
  methods: {
    ...mapActions([
      'SET_TOURNAMENT_GROUPS'
    ])
  },
  computed: {
    ...mapGetters([
      'GET_TOURNAMENT_GROUPS'
    ]),
  },
}
</script>

<style scoped lang="scss">
.ellipse-button {
  @media (max-width: 525px) {
    display: none;
  }
}
</style>
